const fireApi = {
  fire: {
    agent: 'api/agencies/agent/fire',
    byRole: (role: string) => `/api/${ role }/fire_agent`
  }
};

export default fireApi;

export const fireApiConfig = fireApi.fire;
