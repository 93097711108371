import { defineNuxtMiddleware } from '@/types/nuxt2CompositionApiShims';

export default defineNuxtMiddleware(({
  route,
  $auth,
  redirect
}): void => {
  if (!$auth.loggedIn) {
    redirect(`/login?redirectUrl=${ route.path }`);
  }
});
