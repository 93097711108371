const documentsApi = {
  documents: {
    detail: (documentSlug: string) => `/api/documents/slug/${ documentSlug }`,
    getQuizDocuments: (slug: string, bookingId: string) => `/api/agents/questionnaire/${ slug }/groups_of_documents/${ bookingId }`,
    sendPackageDocuments: (bookingId: string) => `/api/agents/questionnaire/${ bookingId }/send`,
    deleteDocument: () => 'api/agents/questionnaire/upload-documents',
    getDocument: (role: string, id: string) => `/api/agencies/${ role }/agreements/${ id }/get_document`
  }
};

export default documentsApi;

export const documentsApiConfig = documentsApi.documents;
