const knowledgeApi = {
  knowledge: {
    items: (slug: string) => `/api/knowbase?city_of_sale=${ slug }`,
    folders: (id: number) => `/api/knowbase?folder_id=${ id }`
  }
};

export default knowledgeApi;

export const knowledgeApiConfig = knowledgeApi.knowledge;
