const quizApi = {
  quiz: {
    getQuiz: (slug: string) => `/api/agents/questionnaire/${ slug }`,
    sendQuestion: (questionId: string) => `/api/agents/questionnaire/${ questionId }`,
    finishedQuiz: (slug: string) => `/api/agents/questionnaire/${ slug }/finish`
  }
};

export default quizApi;

export const quizApiConfig = quizApi.quiz;
