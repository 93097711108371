const pagesApi = {
  pages: {
    broker_registration: '/api/pages/broker_registration',
    check_unique: '/api/pages/check_unique'
  }
};

export default pagesApi;

export const pagesApiConfig = pagesApi.pages;
