const middleware = {}

middleware['authWithBackUrl'] = require('../lk_broker/middleware/authWithBackUrl.ts')
middleware['authWithBackUrl'] = middleware['authWithBackUrl'].default || middleware['authWithBackUrl']

middleware['featureFlags'] = require('../lk_broker/middleware/featureFlags.ts')
middleware['featureFlags'] = middleware['featureFlags'].default || middleware['featureFlags']

middleware['fired'] = require('../lk_broker/middleware/fired.js')
middleware['fired'] = middleware['fired'].default || middleware['fired']

export default middleware
