const projectsApi = {
  projects: {
    /** @deprecated используйте listV3 */
    list: '/api/projects',
    /** @deprecated используйте listV3 */
    listV2: '/api/v2/projects',
    listV3: '/api/v3/projects'
  }
};

export default projectsApi;

export const projectsApiConfig = projectsApi.projects;
