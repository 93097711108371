import { defineStore } from 'pinia';
import {
  ref,
  useContext
} from '@nuxtjs/composition-api';
import { decorateApiActions } from '@@/shared/utilites/logging';
import { Image } from '@/types/Image';
import { pagesApiConfig } from '@/config/api/pages';

type ImagesResponse= Record<string, Image>

interface ImagesState {
  items: Record<string, string>;
  isFetched: boolean;
}
export const useImagesStore = defineStore('images', () => {
  const { $axios } = useContext();
  const images = ref<ImagesState>({
    items: {},
    isFetched: false
  });

  function getPhoto (slug: string): string|null {
    return images.value.items[slug] ?? null;
  }

  async function loadPhotos () {
    if (images.value.isFetched) {
      return;
    }

    const url = pagesApiConfig.broker_registration;
    const { data } = await $axios.get(url);

    images.value.items = preparedResponse(data);
    images.value.isFetched = true;
  }

  function preparedResponse (data: ImagesResponse): Record<string, string> {
    return Object
      .fromEntries(Object
        .entries(data)
        .map(([key, image]) => [
          key,
          image.aws
        ])
      );
  }

  return {
    images,
    getPhoto,
    ...decorateApiActions({
      loadPhotos
    }, '/store/images')
  };
});
