import { defineStore } from 'pinia';
import { KnowledgeResponse } from '@/types/Knowledge';
import { useAxios } from '@/plugins/app-context';
import { knowledgeApiConfig } from '@/config/api/knowledge';

export const useKnowledgeStore = defineStore('knowledge', () => {
  async function getItems (city: string): Promise<KnowledgeResponse> {
    const { data } = await useAxios().get<KnowledgeResponse>(knowledgeApiConfig.items(city));

    return data;
  }

  async function getFolders (id: number): Promise<KnowledgeResponse> {
    const { data } = await useAxios().get<KnowledgeResponse>(knowledgeApiConfig.folders(id));

    return data;
  }

  return {
    getItems,
    getFolders
  };
});
