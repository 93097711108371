const eventsApi = {
  events: {
    getEvent: (id: string) => `/api/events/${ id }`,
    accept: (id: string) => `/api/events/${ id }/accept`,
    refuse: (id: string) => `/api/events/${ id }/refuse`,
    getQRCode: 'api/events_list/qr_code/'
  }
};

export default eventsApi;

export const eventsApiConfig = eventsApi.events;
