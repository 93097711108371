const usersApi = {
  users: {
    dispute: (role: string, userId: string) => `/api/users/${ role }/dispute/${ userId }`,
    pin: (role: string) => `api/users/${ role }/assign_client`
  }
};

export default usersApi;

export const usersApiConfig = usersApi.users;
