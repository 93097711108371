const additionalApi = {
  additional: {
    list: (role: string) => `/api/agencies/${ role }/additionals`,
    additionalAgreementById: (role: string, id: string) => `/api/agencies/${ role }/additionals/${ id }`,
    getDocument: (role: string, id: string) => `/api/agencies/${ role }/additionals/${ id }/get_document`
  }
};

export default additionalApi;

export const additionalApiConfig = additionalApi.additional;
